.WorksPage {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: 800px){
    align-items: flex-end;
  }

  .carousel-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    margin-bottom: 1vh;
    background: linear-gradient(to top, #fffa 40%, #ffffff01);

    @media screen and (min-width: 576px) {
      display: none;
    }

    .paginationButton {
      width: 0.5em;
      height: 0.5em;
      margin: 0.4em;
      padding: 0.25em;
      border: $line_width solid #222;
      transition: 0.3s ease-in;

      &.active {
        transform: translateY(-0.2em) rotate(45deg);
      }
    }
  }
}
