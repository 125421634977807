// colors
$clr_white: #fff !default;
$clr_black: #222 !default;
$clr_structure: #a8b1a8 !default;
$clr_highlight_pale: #f8f8f8 !default;

// fonts
$font_size_small: 12px !default;
$font_size_normal: 14px !default;

$line_width: 1px !default;;
