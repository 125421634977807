.WorksCarousel {
  display: flex;
  flex-direction: column;

  .carousel-body {
    ._carousel-cell_zvdee_31 {
      backface-visibility: visible !important;
      opacity: 1;
    }

    .carousel-nav-arrows {
      button {
        position: absolute;
        top: 45%;
        z-index: 1000;
        padding: 4px;
        transition: 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-aspect-ratio: 3/4) {
          top: 50%;
        }

        .arrow {
          padding: 12px;
          border: solid $clr_black;
          border-width: 0 $line_width $line_width 0;
          box-shadow: 2px 2px 4px -2px $clr_white;
        }

        &.button-left {
          transform: rotate(135deg);
          left: 8vw;

          &:active {
            transform: translateX(-0.5em) rotate(135deg);
          }

          @media screen and (max-width: 600px){
            left: 3vw;
          }
        }

        &.button-right {
          transform: rotate(-45deg);
          right: 8vw;

          &:active {
            transform: translateX(0.5em) rotate(-45deg);
          }

          @media screen and (max-width: 600px){
            right: 3vw;
          }
        }
      }
    }
  }

  .WorksTile {
    &.inBack {
      opacity: 0.8;
      filter: blur(3px) brightness(95%);

      .textContainer {
        opacity: 0.2;
      }
    }

    &.inFront {
      &.gradientFilter:after {
        pointer-events: none;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
      }

      &.leftFront:after {
        background: linear-gradient(to right, white 10%, #ffffff01 22%);
      }

      &.rightFront:after {
        background: linear-gradient(to left, white 10%, #ffffff01 22%);
      }
    }
  }
}
