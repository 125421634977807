.Navigation {
  position: fixed;
  top: 0;
  width: 100vw;
  background: linear-gradient(to bottom, #fff 10%, #fff7 70%, #ffffff01 100%);
  padding-bottom: 2em;
  z-index: 100;

  .navbar {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 740px;

    .navbar-brand {
      margin: 0;
      font-weight: normal;
      font-size: 1.4em;
      text-align: justify;
      text-decoration: unset;
    }

    .navbar-collapse {
      justify-content: flex-end;

      .navbar-nav {
        display: flex;
        align-items: flex-end;

        .nav-link {
          color: $clr_black;
          text-decoration: unset;
          font-size: 1.1em;
          width: fit-content;
          border-bottom: 3px double rgba(0, 0, 0, 0);
          padding: .3rem 1rem;
          margin: 0 0 .5rem 0;

          @media screen and (min-width: 576px) {
            padding: .5em 1em;
            margin: 0 1em;
          }

          &.active {
            border-bottom: 3px double $clr_black;
          }
        }
      }
    }

    .navbar-toggle {
      position: relative;
      border: unset;

      .icon-bar {
        display: block;
        width: 28px;
        height: $line_width;
        background-color: $clr_black;
        transition: ease-in-out 0.2s;
        transform-origin: left center;

        &:not(:first-of-type) {
          margin-top: 7px;
        }
      }

      &:not(.collapsed) {
        .icon-bar {
          width: 24px;
          margin-right: 2px;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: translate(4.5px, 100%);
            width: 0;
            opacity: 0;
            transition: ease-in-out 0.1s;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

}
