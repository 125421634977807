.WorksTile {
  min-height: 358px;
  width: 300px;
  margin: 0 auto 4em;

  a {
    text-decoration: none !important;
  }

  .container {
    width: 100%;
    transition: 0.1s ease-in-out;
    padding: 0.5em 0.5em 1.5em;

    &:hover .imageBox {
      transform: scale(1.02);
    }

    .imageContainer {
      width: 100%;

      .imageBox {
        padding-bottom: 100%;
        background-size: cover;
        background-position: center center;
        transition: 0.1s linear;
      }
    }

    h2 {
      font-size: 2em;
      margin: 0.6em 0 0.3em;
    }

    p {
      margin: 0;
      line-height: 1.6em;
    }

    @media screen and (max-aspect-ratio: 3/4) {
      h2 {
        font-size: 1.8em;
      }

      p {
        font-size: 1em;
      }
    }
  }
}
