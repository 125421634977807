@import "Variables";
// pages
@import "src/pages/ContactPage";
// components
@import "src/components/Layout";
@import "src/components/Navigation";
@import "src/components/WorksTile";
@import "src/components/WorksCarousel";
@import "src/works/Works";

@import "src/pages/WorksPage";

* {
  font-family: Avenir, Helvetica, Futura, Tahoma, sans-serif;
  color: $clr_black;
  font-size: 16px;
}

h1, .navbar-brand {
  font-weight: bold;
  font-size: 5vmax;
  text-align: justify;
  margin: 0 0 1em;
}

h2 {
  font-size: 1em;
  margin-bottom: 2em;
  line-height: 1.5em;
}

h3 {
  font-size: 1.1em;
}

p {
  line-height: 1.8;
  margin-bottom: 1.5em;
}

a {
  color: $clr_black;
  text-decoration: underline;
}

button {
  background-size: 100%;
  background-color: transparent;
  border: unset;

  &:focus {
    outline: 0;
  }
}

svg {
  stroke-width: 15px;
}

.no-text-intent {
  text-indent: unset !important;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
}
