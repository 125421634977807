.Works {
  max-width: 460px;
  margin: 20vh auto;

  .header-container {
    align-items: flex-end;
    justify-content: space-between;
    margin: 2em 0 1em;

    & > * {
      margin: 0;
    }
  }

  p {
    text-indent: 5em;
  }

  img {
    width: 100%;
    margin: 0.5em 0 0.5em;
  }

  &.Vaterland {

    .audioPlayerContainer {
      padding-bottom: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &:hover {
        .audio-player {
          transform: scale(1.3);
          animation: paused;
        }
      }

      img {
        position: relative;
      }

      .audio-player {
        position: absolute;
        padding: 0.5em 0 1.5em;
        animation: blinking 3s ease-in-out infinite;

        div {
          display: flex;
          justify-content: center;

          g {
            &:nth-child(1) {
              circle {
                r: 260px;
              }
            }

            &:nth-child(3) {
              pointer-events: none;

              circle {
                pointer-events: none;
              }
            }

            &:nth-child(5) {
              circle, path {
                fill: transparent;
              }
            }
          }
        }
      }
    }

    @keyframes blinking {
      0% {
        transform: scale(1);
        opacity: 0.8;
      }
      50% {
        opacity: 0.95;
        transform: scale(1.06);
      }
      100% {
        opacity: 0.8;
        transform: scale(1);
      }
    }
  }
}