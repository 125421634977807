.ContactPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .center-container {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0 0 0.5em;
    }

    .link {
      text-decoration: none;
      position: relative;
      color: $clr_black;

      &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -2px;
        border-width: 0 0 2px;
        border-style: solid;
      }

      &:hover {
        &:after {
          bottom: -3px;
          border-width: 0 0 3px;
        }
      }
    }

    .icon {
      margin-top: 5vh;
      align-self: center;
      img {
        width: 5vmax;
        max-width: 2.5em;
      }
      &:hover {
        animation: rotate 1s ease-in-out infinite;
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }

  }
}
